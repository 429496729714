'use client'
import Carousel from 'react-bootstrap/Carousel';
import Image from 'next/image';
import banner from "../../../../../public/banner.webp";
import styles from "./Carrusel.module.css";
import Atencion from "../../../../../public/bannerMobil.webp"
import { useEffect, useState } from 'react';

// Conjuntos de datos de imágenes
const horizontalImagesData = [
  { src: Atencion, alt: "First slide" },
  
];

const verticalImagesData = [
  { src: banner, alt: "First slide" },
  
];

function ControlledCarousel() {
  const [isMobile, setIsMobile] = useState(false);
  const [imagesData, setImagesData] = useState(verticalImagesData);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 622);
    };

    // Comprobar inicialmente
    checkIfMobile();

    // Agregar event listener para cambios de tamaño de ventana
    window.addEventListener('resize', checkIfMobile);

    // Limpiar el event listener
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    setImagesData(isMobile ? horizontalImagesData : verticalImagesData);
  }, [isMobile]);

  return (
    <div className={styles.carouselContainer}>
      <Carousel 
        data-bs-theme="dark" 
        className={styles.carousel}
        interval={5000}
        indicators={true}
      >
        {imagesData.map((image, index) => (
          <Carousel.Item key={index} className={styles.carouselItem}>
            <div className={styles.imageWrapper}>
              <Image
                src={image.src}
                alt={image.alt}

                priority={index === 0}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default ControlledCarousel;

