import type { NextPage } from "next";
import { memo } from "react";
import styles from "./CardDelegacion.module.css";
import delegaciones from "../../../../../JSON/delegaciones.json"
import Image from "next/image";


const CardDelegacion: NextPage = memo(() => {
    return (
      
        <div className={styles.cardAtpWrapper}> 
          {delegaciones.map((delegacion, index) => (
            <div key={delegacion.id} className={styles.cardAtp}>
              <div className={styles.rectangleParent}>
                <div className={styles.frameChild} />
                <div  className={`${styles.frameItem} ${index % 2 === 0 ? styles.grisPrincipal : styles.osp }`} />
              </div>
              <div className={styles.delegacionesWrapper}>            
                  <Image
                    className={styles.delegacionesIcon}
                    alt="Delegaciones"
                    src="/delegaciones-1.svg"
                    width={100}  
                    height={100} 
                    priority  
                  />  
              </div>
              <div className={styles.sectorAgropecuarioWrapper}>
                <div className={styles.sectorAgropecuario}> {delegacion.delegacion}</div>
              </div>
              <div className={styles.esUnSectorContainer}>
                <p className={styles.repblicaDelLbano}>{delegacion.domicilio}</p>
                <p className={styles.repblicaDelLbano}>&nbsp;</p>
                <p className={styles.repblicaDelLbano}>Tel. {delegacion.telefono}</p>
                <p className={styles.horario0730A}>Horario: {delegacion.horario}</p>
              </div>
            </div>
          ))}
        </div>
      
    );
  })
  CardDelegacion.displayName = "CardDelegacion";
export default CardDelegacion;
