
import { memo } from "react";
import InformaciondeServicios from "../../../../../../JSON/InformaciondeServicios.json";
import styles from "../styles/components/Header.module.css";

interface ServiceHeaderProps {
  servicioSeleccionado: string;
  cardbotonRef: React.RefObject<HTMLDivElement>;
}

const ServiceHeader = memo(({ servicioSeleccionado, cardbotonRef }: ServiceHeaderProps) => {
  const servicioInfo = InformaciondeServicios.find(
    (servicio) => servicioSeleccionado.trim().toLowerCase() === servicio.servicio.trim().toLowerCase()
  );

  return (
    <div className={styles.frameParent}>
      <div className={styles.reaAfiliacionesWrapper} ref={cardbotonRef}>
        <h1 className={styles.reaAfiliaciones}>{servicioSeleccionado}</h1>
      </div>
      {servicioInfo && (
        <div className={styles.paraPresentacinDe}>
          {servicioInfo.descripcion}
        </div>
      )}
    </div>
  );
});

ServiceHeader.displayName = "ServiceHeader";
export default ServiceHeader;