
import { memo } from "react";
import InformaciondeServicios from "../../../../../../JSON/InformaciondeServicios.json";
import styles from "../styles/components/ServiceInfo.module.css";

interface ServiceInfoProps {
  servicioSeleccionado: string;
}

const ServiceInfo = memo(({ servicioSeleccionado }: ServiceInfoProps) => {
  const servicioInfo = InformaciondeServicios.find(
    (servicio) => servicioSeleccionado.trim().toLowerCase() === servicio.servicio.trim().toLowerCase()
  );

  if (!servicioInfo) return null;

  return (
    <div className={styles.losTrmitesQueSeRealizanPWrapper}>
      <div className={styles.losTrmitesQueContainer}>
        {servicioInfo.informacionPrimaria && (
          <p className={styles.losTrmitesQue}>
            {servicioInfo.informacionPrimaria}
          </p>
        )}
        {servicioInfo.informacionSecundaria && (
          <p className={styles.losTrmitesQue}>
            {servicioInfo.informacionSecundaria}
          </p>
        )}
      </div>
    </div>
  );
});

ServiceInfo.displayName = "ServiceInfo";
export default ServiceInfo;