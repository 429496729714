
import { memo, useState, useEffect } from "react";
import { ProgressBar } from 'primereact/progressbar';

const CronicosSection = memo(() => {
  const [progress, setProgress] = useState(0);
  const [loadingLocal, setLoadingLocal] = useState(true);

  useEffect(() => {
    if (loadingLocal) {
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => (prevProgress < 50 ? prevProgress + 50 : prevProgress));
      }, 500);
      
      return () => clearInterval(intervalId);
    }
  }, [loadingLocal]);

  return (
    <div className="w-full mx-auto md:aspect-[16/9] h-[450px] mt-[50px] mb-[25px]">
      {loadingLocal && <ProgressBar value={progress} className="mb-3" />}
      <iframe
        src="https://sdf.tandemdigital.net/generador-formulario-cronicos"
        title="Formulario Pacientes Crónicos"
        className="w-full h-full border-0"
        onLoad={() => {
          setLoadingLocal(false);
          setProgress(100);
        }}
      />
    </div>
  );
});

CronicosSection.displayName = "CronicosSection";
export default CronicosSection;