import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\next\\dist\\client\\app-dir\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\components\\Carrusel\\CarruselImagen\\Carrusel.tsx");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\components\\Carrusel\\CarruselVideo\\VideoCarousel.tsx");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\components\\Footer\\footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\components\\Menu\\Menu.tsx");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\components\\RedesSociales\\Facebook\\facebook.module.css");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\components\\RedesSociales\\Instagram\\instagram.module.css");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\components\\RedesSociales\\RedSocial.module.css");
;
import(/* webpackMode: "eager" */ "C:\\WebDos\\Obra-Social-Provincia\\src\\app\\index.module.css");
