import React, { useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Prestador } from "@/app/interfaces/interfaces";
import {useFetchData}  from "@/app/utils/useFetchData";
import { useAppDispatch } from "@/app/hooks/StoreHook";
import { setPrestadores} from '@/app/redux/Slice/filter'
type DataResponse = {
  data: Prestador[]; 
};
const FilterUser = dynamic(() => import('@/app/users/Prestador/Filtros/UseAutocomplete'),);
const FilterEspecialidad = dynamic(() => import('@/app/users/Prestador/Filtros/FilterEspecialidad'));

  const PrestadoresFilters = () => {
    const dispatch = useAppDispatch();
    const resource = useFetchData<DataResponse>('/api/Datos/prestadores');
    const data: DataResponse = resource ? resource.read() : { data: [] };
  
    const filteredData = useMemo(() => {
      return data ? data.data : [];
    }, [data]);
    
    useEffect(() => {
      if (filteredData.length > 0) {
        dispatch(setPrestadores(filteredData));
      }
    }, [filteredData, dispatch]);


    return (
      <div className="container w-full max-w-2xl mx-auto mt-8 px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Cartilla de Prestadores</h2>
    
        <div className="shadow-inner shadow-gray-350 rounded-md mb-12 mt-7 px-4 sm:px-10 py-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
           
            <div className="relative flex justify-center sm:justify-start overflow-hidden">
              <FilterEspecialidad  />
            </div>
    
            <div className="relative flex justify-center sm:justify-start overflow-hidden">
              <FilterUser  />
            </div>
            
          </div>
        </div>
      </div>
    );
    
    
  }    
  export default PrestadoresFilters;
  
  