import type { NextPage } from "next";
import { memo } from "react";
import Facebook from "./Facebook/facebook";
import Instagram from "./Instagram/instagram";
import styles from "./RedSocial.module.css";

type RedSocialProps = {
  location: "menu" | "footer";
};

const RedSocial: NextPage<RedSocialProps> = memo(({ location }) => {
  return (
    <div className={styles.sociales}>
      <Facebook location={location} />
      <Instagram location={location} />
    </div>
  );
});

RedSocial.displayName = "RedSocial";
export default RedSocial;
