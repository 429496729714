
import BotonServicio from "@/app/components/Botones/BotonServicios/boton-servicio";
import styles from "./styles/ServiceButtons.module.css";

interface ServiceButtonsProps {
  servicioSeleccionado: string;
  onServicioClick: (servicio: string) => void;
}

const ServiceButtons = ({ servicioSeleccionado, onServicioClick }: ServiceButtonsProps) => {
  const handleExpediClick = () => {
    window.location.href = "https://sj.sanjuan.gob.ar/?uid=CUMV-VIEW-ExpedientesComponent";
  };

  return (
    <div className={styles.botones}>
      <BotonServicio
        showIcono
        text="Afiliaciones"
        info="/afiliaciones.svg"
        onClick={() => onServicioClick("Afiliaciones")}
        isActive={servicioSeleccionado === "Afiliaciones"}
      />
      <BotonServicio
        showIcono
        text="Prestadores"
        info="/empleados-publicos.svg"
        onClick={() => onServicioClick("Prestadores")}
        isActive={servicioSeleccionado === "Prestadores"}
      />
      <BotonServicio
        showIcono
        text="Cobertura"
        info="/autorizaciones-farmacia.svg"
        onClick={() => onServicioClick("Cobertura")}
        isActive={servicioSeleccionado === "Cobertura"}
      />
      <BotonServicio
        showIcono
        text="Servicios"
        info="/servicios-salud.svg"
        onClick={() => onServicioClick("Servicios")}
        isActive={servicioSeleccionado === "Servicios"}
      />
      <BotonServicio
        showIcono
        text="Programas"
        info="/medicamentos1.svg"
        onClick={() => onServicioClick("Programas")}
        isActive={servicioSeleccionado === "Programas"}
      />
      <BotonServicio
        showIcono
        text="Farmacia y Medicamento"
        info="/farmacia.svg"
        onClick={() => onServicioClick("Farmacia y Medicamento")}
        isActive={servicioSeleccionado === "Farmacia y Medicamento"}
      />
      <BotonServicio
        showIcono
        text="Pacientes Crónicos"
        info="/pacientes-cronicos.svg"
        onClick={() => onServicioClick("Pacientes Crónicos")}
        isActive={servicioSeleccionado === "Pacientes Crónicos"}
      />
      <BotonServicio
        showIcono
        text="Formularios"
        info="/formularios-medicos.svg"
        onClick={() => onServicioClick("Formularios")}
        isActive={servicioSeleccionado === "Formularios"}
      />
      <BotonServicio
        showIcono
        text="Expediente"
        info="/expedienteonline.svg"
        onClick={handleExpediClick}
        isActive={false}
      />
      <BotonServicio
        showIcono
        text="Sistema Online para Prestadores"
        info="/web.svg"
        onClick={() => onServicioClick("Sistema Online para Prestadores")}
        isActive={servicioSeleccionado === "Sistema Online para Prestadores"}
      />
    </div>
  );
};

export default ServiceButtons;