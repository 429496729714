
import { memo} from "react";
import { useAppSelector } from "@/app/hooks/StoreHook";
import Requisitos from "@/app/components/Contenido/AcordeondeRequisitos/Requisitos";
import CardDelegacion from "@/app/components/Cards/CardDelegacion/CardDelegacion";
import PrestadoresSection from "./PrestadoresSection";
import CronicosSection from "./CronicosSection";
import { ContentDisplayProps  } from "@/app/interfaces/interfaces";


const ContentDisplay = memo(({ servicioSeleccionado, contenidoSeleccionado,sinSubSubCategoria,seviciobotonRef }: ContentDisplayProps) => {
  const mostrarDelegacion = useAppSelector((state) => state.navbar.mostrarDelegacion);



  return (
    <div
    ref={seviciobotonRef}   className="flex items-center justify-center w-auto h-auto"
  >
    {mostrarDelegacion ? (
          <CardDelegacion />
        ) : contenidoSeleccionado ? (
          <Requisitos requisitos={contenidoSeleccionado} />
        ) : sinSubSubCategoria.length > 0 ? (
          <Requisitos requisitos={sinSubSubCategoria} />
        ) : null}
      {servicioSeleccionado.toLowerCase() === "prestadores" && (
        <PrestadoresSection />
      )}

      {servicioSeleccionado.toLowerCase() === "pacientes crónicos" && (
        <CronicosSection />
      )}
    </div>
  );
});

ContentDisplay.displayName = "ContentDisplay";
export default ContentDisplay;