
import { useState } from 'react';
import { ContenidoSeleccionado } from "@/app/interfaces/interfaces";

export const useMenuState = () => {
  const [mostrarContenido, setMostrarContenido] = useState(false);
  const [servicioSeleccionado, setServicioSeleccionado] = useState<string>("");
  const [contenidoSeleccionado, setContenidoSeleccionado] = useState<ContenidoSeleccionado[]>([]);

  return {
    mostrarContenido,
    servicioSeleccionado,
    contenidoSeleccionado,
    setMostrarContenido,
    setServicioSeleccionado,
    setContenidoSeleccionado
  };
};