import { memo} from "react";
import Image from "next/image";
import { BotonCardType } from "@/app/interfaces/interfaces";
import { useSearchParams,useRouter,usePathname } from 'next/navigation'
import { obtenerImagenBoton } from './imagenesBotones'; 
 
const BotonCard = memo(({ mostrarIcono = true, botonLabel, onClick, value,index }: BotonCardType) => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const {replace} = useRouter ()

  const handleClick = () => {
    const params = new URLSearchParams(searchParams) 
    if(value){
      params.set('subcategoria', value);
    } else{
      params.delete('subcategoria')
    }
    replace(`${pathname}?${params.toString()}`,{ scroll: false })
    onClick(value);
    
  };

  const bgColor = index % 2 === 0 ? 'bg-[#D31D16]' : 'bg-[#413e43]';
  const hoverColor = index % 2 === 0 ? 'hover:bg-[#E42E27]' : 'hover:bg-[#5e5a60]';
  return (
    
    <div
       className="w-full h-[80px] bg-white relative rounded-[15px] shadow-sm flex justify-start items-center p-4  px-2 hover:cursor-pointer "
      onClick={handleClick}
    >
      {mostrarIcono && (
        <Image
          alt="Boton de Categoria"
          src={obtenerImagenBoton(botonLabel) as string} 
          width={40}
          height={40}
          priority
          className="mr-3"
        />
      )}
        <div className="text-[#413E43] font-bold text-[14px] ">{botonLabel}</div>
        <div className={`absolute w-full ${bgColor} ${hoverColor} bottom-0 z-50 h-2 rounded-b-md left-0`} />
    </div>
  );
});

BotonCard.displayName = "BotonCard";
export default BotonCard;


