
import type { NextPage } from "next";
import { memo, useRef, useState } from "react";
import { ContenidoPrincipalType } from "@/app/interfaces/interfaces";
import ServiceHeader from "./components/ServiceHeader";
import SubcategoryButtons from "./components/SubcategoryButtons";
import ServiceInfo from "./components/ServiceInfo";
import ContentDisplay from "./components/ContentDisplay";
import styles from "./styles/AdministradorDeRequisitos.module.css";

const ContenidoPrincipal: NextPage<ContenidoPrincipalType> = memo(
  ({ servicioSeleccionado, subSubCategorias, cardbotonRef }) => {
    const [contenidoSeleccionado, setContenidoSeleccionado] = useState<Array<{ titulo: string; contenido: string; id: number }> | null>(null);
    const [sinSubSubCategoria, setSinSubSubCategoria] = useState<Array<{ titulo: string; contenido: string; id: number }>>([]);
    const seviciobotonRef = useRef<HTMLDivElement | null>(null);
    return (
      <section className={styles.ospAfiliacionesInner}>
      <div className={styles.areaNavigationParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.areaDescriptionParent}>
            <div className={styles.areaDescription}>
                <ServiceHeader 
                  servicioSeleccionado={servicioSeleccionado} 
                  cardbotonRef={cardbotonRef} 
                />
                <SubcategoryButtons
                  subSubCategorias={subSubCategorias}
                  onContenidoSeleccionadoChange={setContenidoSeleccionado}
                  seviciobotonRef={seviciobotonRef}
                  onSinSubSubCategoriaChange={setSinSubSubCategoria}
                />
              </div>
              <div className={styles.lineaDivisoria} />
              <ServiceInfo 
                  servicioSeleccionado={servicioSeleccionado} 
                />
            </div>
          </div>
          <ContentDisplay
            servicioSeleccionado={servicioSeleccionado}
            contenidoSeleccionado={contenidoSeleccionado}
            sinSubSubCategoria={sinSubSubCategoria}
            seviciobotonRef={seviciobotonRef}
          />
        </div>
      </section>
    );
  }
);

ContenidoPrincipal.displayName = "ContenidoPrincipal";

export default ContenidoPrincipal;