import React from 'react';
import { Skeleton } from 'primereact/skeleton'; 

const PrestadoresSkeleton: React.FC = () => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full text-xs md:text-sm text-left table-auto border-collapse">
        <thead className="bg-[#413e43] text-white border-b-4 border-[#d31d16]">
          <tr>
            <th className="px-4 py-2">Especialidad</th>
            <th className="px-4 py-2">Nombre y Apellido</th>
            <th className="px-4 py-2">Matrícula</th>
            <th className="px-4 py-2">Teléfono</th>
            <th className="px-4 py-2">Dirección</th>
            <th className="px-4 py-2">Fidelizado</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 6 }).map((_, index) => (
            <tr key={index} className="border-t shadow-sm border-[#d31d16]">
              <td className="px-4 py-2 text-black">
                <div className="flex items-center">
                  <Skeleton shape="circle" width="32px" height="32px" className="mr-2" />
                  <Skeleton width="150px" height="20px" />
                </div>
              </td>
              <td className="px-4 py-2 text-black">
                <div className="flex items-center">
                  <Skeleton shape="circle" width="32px" height="32px" className="mr-2" />
                  <Skeleton width="120px" height="20px" />
                </div>
              </td>
              <td className="px-4 py-2 text-black">
                <Skeleton width="80px" height="20px" />
              </td>
              <td className="px-4 py-2 text-black">
                <div className="flex items-center">
                  <Skeleton shape="circle" width="32px" height="32px" className="mr-2" />
                  <Skeleton width="100px" height="20px" />
                </div>
              </td>
              <td className="px-4 py-2 text-black">
                <div className="flex items-center">
                  <Skeleton shape="circle" width="32px" height="32px" className="mr-2" />
                  <Skeleton width="150px" height="20px" />
                </div>
              </td>
              <td className="px-4 py-2 text-black">
                <Skeleton width="80px" height="20px" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrestadoresSkeleton;


