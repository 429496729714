
import type { NextPage } from "next";
import { memo } from "react";
import { useCardRequisitos } from "./hooks/useCardRequisitos";
import { CardRequisitosType } from "@/app/interfaces/interfaces";
import CardGrid from "./components/CardGrid";

const CardRequisitos: NextPage<CardRequisitosType> = memo(({
  servicioSeleccionado,
  onPublicacionesFiltradasChange,
  subcategoriaRef
}) => {
  const {
    publicacionesAgrupadas,
    handleBotonCardClick,
    handleRedireccionClick
  } = useCardRequisitos({
    servicioSeleccionado,
    onPublicacionesFiltradasChange,
    subcategoriaRef
  });

  return (
    <section className="w-full my-6">
      <CardGrid
        publicacionesAgrupadas={publicacionesAgrupadas}
        servicioSeleccionado={servicioSeleccionado}
        onBotonCardClick={handleBotonCardClick}
        onRedireccionClick={handleRedireccionClick}
      />
    </section>
  );
});

CardRequisitos.displayName = "CardRequisitos";
export default CardRequisitos;