import React from 'react';
import styles from './search.module.css';
import Image from "next/image";
const SearchBar = () => {
  return (
    <div className={styles.busquedaParent}>
      <div className={styles.busqueda}>
        <input
          className={styles.queTrmiteO}
          placeholder="Buscar..."
          type="text"
        />
      </div>
      <Image 
      className={styles.lupa2Icon}
      alt="Lupa"
      src="/lupa-2.svg"
      width={40}  
      height={40} 
      style={{ width: "auto", height: "auto" }}
      priority /> 
    </div> 
  );
};

export default SearchBar;
