import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationButtonsProps } from "@/app/interfaces/interfaces";

export default function PaginationButtons({ page, setPage, maxPage }: PaginationButtonsProps) {
  const handleChange = (event: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };

  return (
    <Stack spacing={2} direction="column" alignItems="center" className="mt-4 mb-5">
      <span className="text-xs text-center">Se Encuentra en la Pagina: {page}</span>
      <Pagination
        count={maxPage}
        page={page}
        onChange={handleChange}
        variant="outlined"
   
        showFirstButton showLastButton
        sx={{
          '& .MuiPaginationItem-root': {
            color: '#413e43',
            '&:hover': {
              backgroundColor: '#6f7b7e', 
              color: '#fff', 
            },
          }, '& .MuiPaginationItem-ellipsis': {
            pointerEvents: 'none',
          },
          '& .Mui-selected': {
            backgroundColor: '#d31d16 !important', 
            color: '#fff', 
            '&:hover': {
              backgroundColor: '#d31d16', 
            },
          },
        }}
      />
    </Stack>
  );
}
