"use client";
import { memo, useEffect, useRef, useCallback } from "react";
import { useSearchParams, useRouter } from 'next/navigation';
import { useAppSelector, useAppDispatch } from '@/app/hooks/StoreHook';
import { setActiveButton, setMostrarDelegacion } from '@/app/redux/Slice/navbarSlice';
import styles from "./styles/Menu.module.css";
import Header from "./Header";
import ServiceButtons from "./ServiceButtons";
import ContentSection from "./ContentSection";
import ContactCards from "./ContactCards";
import { useMenuState } from "./useMenuState";

const Menu = memo(() => {
  const {
    mostrarContenido,
    servicioSeleccionado,
    contenidoSeleccionado,
    setMostrarContenido,
    setServicioSeleccionado,
    setContenidoSeleccionado
  } = useMenuState();

  const activeButton = useAppSelector((state) => state.navbar.activeButton);
  const mostrarDelegacion = useAppSelector((state) => state.navbar.mostrarDelegacion);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const queryParams = useSearchParams();
  const servicioQuery = queryParams.get('servicio')?.toString();

  const cardRequisitosRef = useRef<HTMLDivElement | null>(null);
  const subcategoriaRef = useRef<HTMLDivElement>(null);

  const handleServicioClick = useCallback((servicio: string) => {
    setServicioSeleccionado(servicio);
    setMostrarContenido(true);
    setContenidoSeleccionado([]);
    dispatch(setActiveButton(false));
    dispatch(setMostrarDelegacion(false));
    cardRequisitosRef.current?.scrollIntoView({ behavior: "auto", block: "start" });
  }, [dispatch, setServicioSeleccionado, setMostrarContenido, setContenidoSeleccionado]);

  const handleCerrarClick = () => {
    setMostrarContenido(false);
    setServicioSeleccionado("");
    if (activeButton) {
      dispatch(setActiveButton(false));
    }
    if (mostrarDelegacion) {
      dispatch(setMostrarDelegacion(false));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      router.push("/");
    }, 300);
  };

  useEffect(() => {
    if (servicioQuery) {
      handleServicioClick(servicioQuery);
      setTimeout(() => {
        cardRequisitosRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 200);
    }
  }, [servicioQuery, handleServicioClick]);

  return (
    <section className={styles.ospLandingInner}>
      <div className={styles.cabeceraConIconosParent}>
      <div className={styles.cabeceraConIconos}>
         <Header
         />
        <ServiceButtons 
          servicioSeleccionado={servicioSeleccionado}
          onServicioClick={handleServicioClick}
        />
      </div>

        <ContentSection 
          mostrarContenido={mostrarContenido}
          servicioSeleccionado={servicioSeleccionado}
          contenidoSeleccionado={contenidoSeleccionado}
          onCerrarClick={handleCerrarClick}
          cardRequisitosRef={cardRequisitosRef}
          subcategoriaRef={subcategoriaRef}
          setContenidoSeleccionado={setContenidoSeleccionado}
        />
        <ContactCards/>
      </div>
    </section>
  );
});

Menu.displayName = "Menu";
export default Menu;