import React from 'react';

const FilterSkeleton: React.FC = () => {
  return (
    <div className="container w-full max-w-2xl mx-auto mt-8 px-4 sm:px-6 lg:px-8 animate-pulse">
      <h2 className="text-2xl font-bold text-gray-300 mb-6 text-center bg-gray-300 rounded-md h-8 w-48 mx-auto"></h2>
      
      <div className="shadow-inner shadow-gray-300 rounded-md mb-12 mt-7 px-4 sm:px-10 py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          
          <div className="relative flex justify-center sm:justify-start overflow-hidden">
            <div className="bg-gray-300 h-12 w-full rounded-md"></div>
          </div>

          <div className="relative flex justify-center sm:justify-start overflow-hidden">
            <div className="bg-gray-300 h-12 w-full rounded-md"></div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default FilterSkeleton;
