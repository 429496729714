import styles from "./facebook.module.css";
import Link from "next/link";
import Image from "next/image";


type FacebookProps = {
  location: "menu" | "footer";
};

const Facebook: React.FC<FacebookProps> = ({ location }) => {
  return (
    <div className={styles.facebook}>
      <div className={styles.facebookChild} />
      <Link href="https://www.facebook.com/obrasocial.sanjuan.gob?mibextid=ZbWKwL">
      <Image 
      className={styles.facebookIcon} 
      alt="Facebook" 
      src={location === "menu" ? "/facebook.svg" : "/facebook-1.svg"}
      width={50}  
          height={50} 
          priority  />
      </Link>
    </div>
  );
};
Facebook.displayName = "Facebook";
export default Facebook;
