
import { memo, useState, useEffect} from "react";
import {useAppDispatch} from "@/app/hooks/StoreHook"
import { setActiveButton } from '@/app/redux/Slice/navbarSlice';
import BotonSubSubCategoria from "@/app/components/Botones/BotonSubsubCategoria/BotonSubsubCategoria";
import styles from "../styles/components/SubcategoryButtons.module.css";
import { SubcategoryButtonsProps  } from "@/app/interfaces/interfaces";


const SubcategoryButtons = memo(({ 
  subSubCategorias, 
  onContenidoSeleccionadoChange, 
  seviciobotonRef, 
  onSinSubSubCategoriaChange 
}: SubcategoryButtonsProps) => {
  
  const [subCategoriasAgrupadas, setSubCategoriasAgrupadas] = useState<{[key: string]: { titulo: string; contenido: string; id: number }[];}>({});
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (subSubCategorias.length === 0) return; // Solo ejecuta si hay datos

    const { agrupadas, sinNombre } = subSubCategorias.reduce(
      (acc, pub) => {
        const { subSubCategoriaNombre, titulo, contenido, id } = pub;
        if (subSubCategoriaNombre) {
          if (!acc.agrupadas[subSubCategoriaNombre]) {
            acc.agrupadas[subSubCategoriaNombre] = [];
          }
          acc.agrupadas[subSubCategoriaNombre].push({ titulo, contenido, id });
        } else {
          acc.sinNombre.push({ titulo, contenido, id });
        }
        return acc;
      },
      { agrupadas: {} as { [key: string]: { titulo: string; contenido: string; id: number }[] }, sinNombre: [] as { titulo: string; contenido: string; id: number }[] }
    );
    setSubCategoriasAgrupadas(agrupadas);
    onSinSubSubCategoriaChange(sinNombre);
  }, [subSubCategorias,onSinSubSubCategoriaChange]);

  const handleSubSubCategoriaClick = (subsubcategoriaNombre: string) => {
    if (selectedCategory === subsubcategoriaNombre) {
      setSelectedCategory(null);
      onContenidoSeleccionadoChange(null);
      dispatch(setActiveButton(false));
    } else {
      setSelectedCategory(subsubcategoriaNombre);
      const contenidoDeSub = subCategoriasAgrupadas[subsubcategoriaNombre];
      onContenidoSeleccionadoChange(contenidoDeSub);
      dispatch(setActiveButton(true));
    }
    
    seviciobotonRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };


  return (
    <div className={styles.areaActionWrapper}>
      <div className={styles.areaAction}>
        {Object.keys(subCategoriasAgrupadas).map((subsubcategoriaNombre, index) => (
          <BotonSubSubCategoria
            key={subsubcategoriaNombre}
            text={subsubcategoriaNombre}
            registroBlanco="/registro-blanco.svg"
            onClick={() => handleSubSubCategoriaClick(subsubcategoriaNombre)}
            index={index}
            titulo=""
            contenido=""
            id={0}
          />
        ))}
      </div>
    </div>
  );
});

SubcategoryButtons.displayName = "SubcategoryButtons";
export default SubcategoryButtons;