
import { Suspense } from "react";
import Image from "next/image";
import ErrorBoundary from "@/app/utils/ErrorBoundary";
import CardRequisitos from "@/app/components/Cards/CardRequisitos/CardRequisitos";
import AdministradorDeRequisitos from "@/app/components/Contenido/AdministradordeRequisitos/AdministradorDeRequisitos"
import { ContenidoSeleccionado } from "@/app/interfaces/interfaces";
import styles from "./styles/ContentSection.module.css";

interface ContentSectionProps {
  mostrarContenido: boolean;
  servicioSeleccionado: string;
  contenidoSeleccionado: ContenidoSeleccionado[];
  onCerrarClick: () => void;
  cardRequisitosRef: React.RefObject<HTMLDivElement>;
  subcategoriaRef: React.RefObject<HTMLDivElement>;
  setContenidoSeleccionado: (contenido: ContenidoSeleccionado[]) => void;
}

const ContentSection = ({
  mostrarContenido,
  servicioSeleccionado,
  contenidoSeleccionado,
  onCerrarClick,
  cardRequisitosRef,
  subcategoriaRef,
  setContenidoSeleccionado,
}: ContentSectionProps) => {
  if (!mostrarContenido) return null;

  return (
    <>
      <div className="flex w-56 gap-2" onClick={onCerrarClick} ref={cardRequisitosRef}>
        <div className="bg-[#413E43] px-2 rounded-l-md hover:cursor-pointer hover:bg-[#524F54] transition-colors">
          <Image
            className="h-full"
            alt=""
            src="/boton-area.svg"
            width={25}
            height={25}
            priority
          />
        </div>
        <button className="w-48 bg-[#D31D16] font-semibold text-white h-10 rounded-r-md hover:cursor-pointer hover:bg-[#E42E27] transition-colors">
          {servicioSeleccionado}
        </button>
      </div>
      <ErrorBoundary fallback={<div>Error al cargar las publicaciones.</div>}>
        <Suspense fallback={<div>Cargando...</div>}>
          <CardRequisitos
            servicioSeleccionado={servicioSeleccionado}
            onPublicacionesFiltradasChange={setContenidoSeleccionado}
            subcategoriaRef={subcategoriaRef}
          />
        </Suspense>
      </ErrorBoundary>
      <div className={styles.cabecerarequisito}>
        <AdministradorDeRequisitos
          subSubCategorias={contenidoSeleccionado}
          servicioSeleccionado={servicioSeleccionado}
          cardbotonRef={subcategoriaRef}
        />
      </div>
    </>
  );
};

export default ContentSection;