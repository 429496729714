import type { NextPage } from "next";
import { memo} from "react";
import {useAppSelector} from "@/app/hooks/StoreHook"
import {BotonPrincipalType} from "@/app/interfaces/interfaces"
import { sendGAEvent } from '@next/third-parties/google'

const BotonPrincipal: NextPage<BotonPrincipalType> = memo(({ text = "", onClick, index }) => {
    
    const bgColor = index % 2 === 0 ? 'bg-[#D31D16]' : 'bg-[#413e43]';
    const hoverColor = index % 2 === 0 ? 'hover:bg-[#E42E27]' : 'hover:bg-[#5e5a60]';
    const  activeButton  = useAppSelector(state =>  state.navbar.activeButton);

    const handleClick = () => {
      sendGAEvent('event', 'buttonClicked', { value: text });
      onClick(activeButton ? "" : text, activeButton ? "" : text, index); 
    };
    return (
      <button
      className={`w-56 h-[50px] font-semibold rounded-[5px] flex items-center justify-center gap-4 px-4 py-2 m-4 ${bgColor} ${hoverColor} text-white transition-colors`}
        onClick={handleClick}>
        <div className="text-white text-sm">
          {text}
        </div>
      </button>
    );
    
  }
);
BotonPrincipal.displayName = "BotonPrincipal";
export default BotonPrincipal;

