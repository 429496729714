
import CardContacto from "@/app/components/Cards/CardContacto/CardContacto";
import styles from "./styles/ContactCards.module.css";



const ContactCards = () => {
  return (
    <div className={styles.contactParent}>
    <CardContacto
            textPrncipal="Horario de Atención"
            textSecundario="de Lunes a Viernes de 7hs a 13hs"
            mostrarIcono
            propOverflow="hidden"
            fondo="/horario.svg"
          />
          <CardContacto
            textPrncipal="Nuestro Telefóno"
            textSecundario="0264 4304300"
            mostrarIcono
            propOverflow="hidden"
            fondo="/contactotelefono.svg"
          />
          <CardContacto
            textPrncipal="Nuestro email"
            textSecundario="obrasocialprovincia @sanjua.gov.ar"
            mostrarIcono
            propOverflow="hidden"
            fondo="/mail.svg"
          />
          
          <CardContacto
            textPrncipal="Delegaciones"
            textSecundario="Conocé nuestras distintas delegaciones en Servicios"
            mostrarIcono
            fondo="/direcciondelegacion.svg"
            />
    </div>
  );
};

export default ContactCards;