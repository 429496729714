
const imagenesBotones: { [key: string]: string } = {
    "Centros de Rehabilitación Adulto": "/centros.svg",
    "Centros de Rehabilitación Niños": "/centros.svg",
    "Centros de Día - Centro Educativo Terapeutico (Discapacidad)": "/centros.svg",
    "Celiaquia": "/celeaquia.svg",
    "Asistencia Económica de Traslado por Discapacidad": "/discapacidad.svg",
    "Internación Geriátrica": "/internacionG.svg",
    "Internación Domiciliaria": "/internacionD.svg",
    "Cobertura de  DAI": "/dai.svg",
    "Prótesis y Órtesis": "/protesis.svg",
    "Clinicas": "/Institucion.svg",
    "Laboratorios": "/laboratorio.svg",
    "Proveedores": "/proveedor.svg",
    "Servicio de Sepelio": "/sepelio.svg",
    "Delegación de Partamental": "/direcciondelegacion.svg",
    "Farmacia": "/farmacia2.svg",
    "Medicamento": "/farmacia.svg",
    "Descarga de Formularios": "/formulariosDescarga.svg",
    "Convenios Radicados y Estudiantes": "/convenio.svg",

     
    
  };
  export const obtenerImagenBoton = (botonLabel: string): string => {
    return imagenesBotones[botonLabel] || "/expediente-3-6.svg"; // Valor por defecto
  };
  