import styles from "./instagram.module.css";
import Link from "next/link";
import Image from "next/image";

type InstagramProps = {
  location: "menu" | "footer";
};

const Instagram: React.FC<InstagramProps> = ({ location }) => {
  return (
    <div className={styles.instagram}>
      <div
        className={styles.instagramChild} 
       
      />
      <Link href="https://www.instagram.com/obrasocialprovincia?igsh=MWRoNHllcG5wdzA4dA==">
        <Image
          className={styles.instgramIcon} 
          alt="Instagram"
          src={location === "menu" ? "/instgram.svg" : "/instgram-1.svg"}
          width={50}
          height={50}
          priority
        />
      </Link>
    </div>
  );
};
Instagram.displayName = "Instagram";
export default Instagram;

