import type { NextPage } from "next";
import { CSSProperties, memo, useMemo} from "react";
import styles from "./CardContacto.module.css";
import {CardContactoType} from "@/app/interfaces/interfaces"
import Image from "next/image";

const CardContacto: NextPage<CardContactoType> = memo(
  ({
    textPrncipal = "Delegaciones",
    textSecundario = "conocé nuestras distintas delegaciones",
    mostrarIcono = true,
    propOverflow,
    fondo,
    onClick,
  }) => {
    const expediente3IconStyle: CSSProperties = useMemo(() => {
      return {
        overflow: propOverflow,
      };
    }, [propOverflow]);

    return (
      <div className={styles.cardVertical }   onClick={onClick}>
        <div className={styles.cardVerticalChild} />
        <div className={styles.cardVerticalInner}>
          <div className={styles.frameParent}>
            <div className={styles.expediente3Wrapper}>
              {mostrarIcono && (
                <Image
                  className={styles.expediente3Icon}
                  alt=""
                  src={fondo || '/path/to/default/image.jpg'} 
                  width={50}
                  height={50}
                  priority
                  style={expediente3IconStyle}
                />
              )}
            </div>
            <h3 className={styles.ingreseContenido}>{textPrncipal}</h3>
            <div className={styles.direccinDelRegistroDelEstWrapper}>
              <div className={styles.direccinDelRegistro}>{textSecundario}</div>
            </div>
          </div>
        </div>
        <div className={styles.borderDecoration}></div>
      </div>
    );
  }
);
CardContacto.displayName = "CardContacto";
export default CardContacto;
