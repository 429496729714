"use client";
import { memo, Suspense } from "react";
import { useAppSelector } from "@/app/hooks/StoreHook";
import ErrorBoundary from "@/app/utils/ErrorBoundary";
import Prestadores from "@/app/users/Prestador/Tabla";
import PrestadoresFilters from "@/app/users/Prestador/Filtros/PrestadoresFilters";
import FilterSkeleton from "@/app/users/Prestador/Filtros/FilterSkeleton";
import PrestadoresSkeleton from "@/app/users/Prestador/PrestadoresSkeleton";
import styles from "../styles/AdministradorDeRequisitos.module.css";

const PrestadoresSection = memo(() => {
  const filtrosAplicados = useAppSelector((state) => state.filter.filtrosAplicados);

  return (
    <div className={styles.tableContainer}>
    
      <Suspense fallback={<FilterSkeleton />}>
        <PrestadoresFilters />
      </Suspense>
      
      {filtrosAplicados ? (
        <ErrorBoundary fallback={<div className="ms-5 text-base">Error loading prestadores.</div>}>
          <Suspense fallback={<PrestadoresSkeleton />}>
            <Prestadores />
          </Suspense>
        </ErrorBoundary>
      ) : (
        <div className="ms-5 text-base">
          No hay Resultados... Por favor! Selecciona alguna Opcion.
        </div>
      )}
    </div>
  );
});

PrestadoresSection.displayName = "PrestadoresSection";
export default PrestadoresSection;