
import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from 'next/navigation';
import { useAppSelector, useAppDispatch } from "@/app/hooks/StoreHook";
import { setActiveButton, setMostrarDelegacion } from '@/app/redux/Slice/navbarSlice';
import { setLoading } from '@/app/redux/Slice/loading';
import fetchData from "@/app/utils/fetchData";
import { DataType,CardRequisitosType, Publicacion } from "@/app/interfaces/interfaces";
import { PublicacionesAgrupadasType } from "@/app/interfaces/interfaces";

const apiData = fetchData<DataType>('/api/Datos/Publicaciones/FetchPublicactions');



export const useCardRequisitos = ({
  servicioSeleccionado,
  onPublicacionesFiltradasChange,
  subcategoriaRef
}: CardRequisitosType) => {
  const [publicacionesAgrupadas, setPublicacionesAgrupadas] = useState<PublicacionesAgrupadasType>({});
  const activeButton = useAppSelector(state => state.navbar.activeButton);
  const dispatch = useAppDispatch();
  const queryParams = useSearchParams();
  const subcategoriaQuery = queryParams.get('subcategoria')?.toString();
  const processFetchedData = (data: Publicacion[]) => {
    if (!Array.isArray(data)) {
      console.error('Los datos no son un array:', data);
      return;
    }
  
    // Filtrar datos según el servicio seleccionado
    const filtradas = data.filter(publicacion =>
      publicacion.categoriaNombre.toLowerCase() === servicioSeleccionado.toLowerCase()
    );
  
    // Agrupar datos por subcategoría
    const agrupadas = filtradas.reduce((acc: Record<string, Publicacion[]>, publicacion) => {
      const { subCategoriaNombre } = publicacion;
  
      if (!acc[subCategoriaNombre]) {
        acc[subCategoriaNombre] = [];
      }
  
      acc[subCategoriaNombre].push(publicacion);
      return acc;
    }, {});
  
    // Actualizar estado global o local
    setPublicacionesAgrupadas(agrupadas);
  
    // Desactivar el estado de carga si hay datos agrupados
    if (Object.keys(agrupadas).length > 0) {
      dispatch(setLoading(false));
    }
  };
  
  const fetchDataWithDelay = useCallback(() => {
    dispatch(setLoading(true));
  
    const attemptFetch = () => {
      try {
        const data = apiData.read();
    
        // Procesar los datos directamente como un array
        if (Array.isArray(data)) {
          processFetchedData(data);
          return;
        } else {
          console.error('La respuesta no es un array válido:', data);
        }
      } catch (error) {
        console.error('Error al intentar obtener los datos:', error);
      }
    
    
    

      // Si los datos no están disponibles, vuelve a intentar
      setTimeout(attemptFetch, 3102);
    };

    // Inicia el primer intento de carga
    attemptFetch();
  }, [dispatch, servicioSeleccionado]);


  const handleBotonCardClick = (subcategoria: string, value: string) => {
    const publicacionesSubcategoria = publicacionesAgrupadas[subcategoria] || [];
    const filtradasFinales = publicacionesSubcategoria.map(publicacion => ({
      id: publicacion.id,
      titulo: publicacion.titulo,
      contenido: publicacion.contenido,  
      subSubCategoriaNombre: publicacion.subSubCategoriaNombre || null,
    }));
    
    
    onPublicacionesFiltradasChange(filtradasFinales);
    subcategoriaRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });

    dispatch(setMostrarDelegacion(value === 'Delegación'));
    if (activeButton) {
      dispatch(setActiveButton(false));
    }
  };

  const handleRedireccionClick = (url?: string) => {
    if (url) {
      window.location.href = url;
    }
  };

  useEffect(() => {
    fetchDataWithDelay();
  }, [servicioSeleccionado, fetchDataWithDelay]);

  useEffect(() => {
    if (subcategoriaQuery && Object.keys(publicacionesAgrupadas).length > 0) {
      const value = subcategoriaQuery === 'Delegación' ? 'Delegación' : subcategoriaQuery;
      handleBotonCardClick(subcategoriaQuery, value);

      setTimeout(() => {
        subcategoriaRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      }, 200);

      dispatch(setMostrarDelegacion(subcategoriaQuery === 'Delegación'));
    }
  }, [subcategoriaQuery, publicacionesAgrupadas, dispatch]);

  return {
    publicacionesAgrupadas,
    handleBotonCardClick,
    handleRedireccionClick
  };
};