"use client"
import React, { useState, useEffect, useCallback, useMemo} from "react";
import { Prestador } from "@/app/interfaces/interfaces";
import PaginationButtons from "@/app/components/Pagination/Pagination"; 
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector} from "@/app/hooks/StoreHook";
import Image from "next/image";



const TABS = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Fidelizado', value: 'Fidelizado' },
  { label: 'No Fidelizado', value: 'No Fidelizado' }
];


const Prestadores = () => {
  const prestadores = useAppSelector(state =>  state.filter.prestadores);
  const filteredDataUser = useAppSelector(state =>  state.filter.filteredDataUser);
  const filteredDataEspecialidad =  useAppSelector(state =>  state.filter.filteredDataEspecialidad);
  const [selectedType, setSelectedType] = useState("Todos");
  const [combinedFilteredData, setCombinedFilteredData] = useState<Prestador[]>([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;


  const maxPage = Math.ceil(combinedFilteredData.length / itemsPerPage);

  const currentPrestadores = useMemo(() => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return combinedFilteredData.slice(start, end);
  }, [page, combinedFilteredData]);

  // Manejar el cambio de pestaña (filtro fidelización)
  const handleTabChange = useCallback((value: string) => {
    setSelectedType(value);
    setPage(1);
  }, []);



  // Filtrar por fidelización
  useEffect(() => {
    let filtered = prestadores;
    // Filtrar por fidelización
    if (selectedType !== 'Todos') {
      const isFidelizado = selectedType === 'Fidelizado';
      filtered = filtered.filter((prestador) =>
        isFidelizado ? prestador.Fidelizado === "1" : prestador.Fidelizado !== "1"
      );
    }
  
    // Filtrar por especialidad si hay una seleccionada
    if (filteredDataEspecialidad.length > 0) {
      filtered = filtered.filter((prestador) =>
        filteredDataEspecialidad.includes(prestador)
      );
    }
    if (filteredDataUser.length > 0) {
      filtered = filtered.filter((prestador) =>
        filteredDataUser.includes(prestador)
      );
    }
    // Actualizar los datos combinados
    setCombinedFilteredData(filtered);
    setPage(1); 
  }, [prestadores, selectedType, filteredDataEspecialidad, filteredDataUser]);


  

  return (
    <div className="container mx-auto ">
    <div className="w-full flex justify-center">
        <div className="w-full">
          <ul className="flex flex-wrap space-x-2 bg-[#EEEBEB] text-white p-2 rounded-t-md" >
            {TABS.map(({ label, value }) => (
              <li
                key={value}
                className={`px-4 py-2  text-sm cursor-pointer ${
                  selectedType === value ? 'bg-[#d31d16] text-white' : 'bg-white text-black'
                } rounded-t-md`}
                onClick={() => handleTabChange(value || 'Todos')}
              >
                {label}
              </li>
            ))}
          </ul>
        </div>
      </div>


  <div className="overflow-x-auto ">
  <table className="w-full text-xs sm:text-sm text-left table-auto border-collapse">
      <thead className="bg-[#413e43] text-white border-b-4 border-[#d31d16]">
        <tr >
        <th className="px-2 sm:px-4 py-2">Especialidad</th>
            <th className="px-2 sm:px-4 py-2">Nombre y Apellido</th>
            <th className="px-2 sm:px-4 py-2">Matrícula</th>
            <th className="px-2 sm:px-4 py-2">Teléfono</th>
            <th className="px-2 sm:px-4 py-2">Dirección</th>
            <th className="px-2 sm:px-4 py-2">Fidelizado</th>
        </tr>
      </thead>
      <tbody > 
      {currentPrestadores.map((prestador) => (
            <tr key={prestador.id || uuidv4()} className="border-b shadow-sm shadow-gray-350 border-[#d31d16]">
              <td className="px-2 sm:px-4 py-2 text-black">
                <div className="flex items-center space-x-2">
                  <Image
                    src="pacientes-cronicos.svg"
                    className="w-6 h-6 sm:w-8 sm:h-8 ml-2"
                    alt="especialidad"
                    width={30}
                    height={30}
                    priority
                  />
                  <span>
                    {prestador.especialidad} <ChevronRightOutlinedIcon /> {prestador.esp1_nom} <ChevronRightOutlinedIcon /> {prestador.esp2_nom}
                  </span>
                </div>
              </td>
              <td className="px-2 sm:px-4 py-2 text-black">
                <div className="flex items-center space-x-2 mr-3">
                  <Image
                    src="prestadortable.svg"
                    className="w-6 h-6 sm:w-8 sm:h-8 ml-4"
                    alt="prestador"
                    width={30}
                    height={30}
                    priority
                  />
                  <span >{prestador.Nombre}</span>
                </div>
              </td>
              <td className="px-2 sm:px-4 py-2 text-black ">{prestador.Matricula}</td>
              <td className="px-2 sm:px-4 py-2 text-black">
                <div className="flex items-center space-x-2">
                  <Image
                    src="telefono.svg"
                    className="w-6 h-6 sm:w-8 sm:h-8 ml-4"
                    alt="telefono"
                    width={30}
                    height={30}
                    priority
                  />
                  <span >{prestador.Telefono}</span>
                </div>
              </td>
              <td className="px-2 sm:px-4 py-2 text-black">
                <div className="flex items-center space-x-2">
                  <Image
                    src="direccion.svg"
                    className="w-6 h-6 sm:w-8 sm:h-8 ml-4"
                    alt="direccion"
                    width={30}
                    height={30}
                    priority
                  />
                  <span >{`${prestador.Domicilio} - ${prestador.Localidad}`}</span>
                </div>
              </td>
              <td className="px-2 sm:px-4 py-2 text-black">
                {prestador.Fidelizado === "1" ? (
                  <div className="flex items-center space-x-2 mr-3">
                    <span>Fidelizado</span>
                    <Image
                      src="fidelizado.svg"
                      className="w-6 h-6 sm:w-8 sm:h-8 "
                      alt="fidelizado"
                      width={30}
                      height={30}
                      priority
                    />
                  </div>
                ) : (
                  <span className="text-red-500 flex items-center space-x-2">
                    <span>No Fidelizado</span>
                    <RemoveCircleOutlineIcon 
                     className="text-red-500" 
                    sx={{ width: 35, height: 35 }} 
                     />
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <div className="flex justify-center items-center mt-4 mb-5">
      <PaginationButtons
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        data={[]}
       
      />
    </div>
  </div>
);
};

export default Prestadores;