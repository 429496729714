import type { NextPage } from "next";
import { memo } from "react";
import styles from "./boton-servicio.module.css";
import { sendGAEvent } from '@next/third-parties/google'
import Image from "next/image";
import {BotonServicioType } from "@/app/interfaces/interfaces"
import { useSearchParams,useRouter,usePathname } from 'next/navigation'

const BotonServicio: NextPage<BotonServicioType >  = memo(
  ({  showIcono = true, text = "", info, onClick, isActive }) => {
    const searchParams = useSearchParams()
    const pathname = usePathname()
    const {replace} = useRouter ()

 
    const handleClick = () => {
      // Enviar evento a Google Analytics
      sendGAEvent('event', 'buttonClicked', { value: text });

      const params = new URLSearchParams(searchParams);

      // Setear el nuevo `servicio` y limpiar `subcategoria`
      if (text) {
        params.set('servicio', text);
        params.delete('subcategoria');  // Eliminar `subcategoria` al seleccionar un nuevo `servicio`
      } else {
        params.delete('servicio');
      }

      // Actualizar la URL sin recargar la página
      replace(`${pathname}?${params.toString()}`, { scroll: false });

      if (onClick) {
        onClick();
      }
    };

    return (
      <div
        className={`${styles.botonServicio} ${isActive ? styles.active : ''}`}
        onClick={handleClick}
      >
        <div className={`${styles.infoWrapper} ${isActive ? styles.active : ''}`}>
          {showIcono && (
            <Image
              className={styles.infoIcon}
              alt=""
              src={info ?? "/expediente-3-6.svg"}
              width={45}
              height={45}
              priority
            />
          )}
        </div>
        <div className={styles.misDocumentos}>{text}</div>
      </div>
    );
  }
);
BotonServicio.displayName = "BotonServicio";
export default BotonServicio;
