
import Image from "next/image";
import styles from "./styles/Header.module.css";
import RedSocial from "@/app/components/RedesSociales/RedSocial";
import SearchBar from "@/app/components/Search/search";
const Header = () => {
  return (
    <div className={styles.cabeceraHeader}>
      <Image
        className={styles.headerSpacingIcon}
        alt="Obra Social Provincia"
        src="/frame.svg"
        width={224}
        height={139}
        priority
      />
      <div className={styles.socialButtonsContainer}>
        <div className={styles.socialesWrapper}>
        <RedSocial location="menu" />
        </div>
        <SearchBar />
      </div>
    </div>
  );
};

export default Header;